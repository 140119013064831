<template>
  <el-dialog title="编辑参数" :visible.sync="settingVisible" width="600px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="form-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="设定制热温度" prop="temperature">
            <el-input v-model="ruleForm.temperature" style="width:300px;margin-right:5px;" placeholder="请设置制热目标温度"/>
        </el-form-item>
        <el-form-item label="热负荷预测值" prop="loadPower">
            <el-input v-model="ruleForm.loadPower" style="width:300px;margin-right:5px;" placeholder="请设置热负荷预测值"/>
        </el-form-item>
        <el-form-item label="负荷计算系数1" prop="loadParam1">
            <el-input v-model="ruleForm.loadParam1" style="width:300px;margin-right:5px;" placeholder="请设置负荷计算系数1"/>
        </el-form-item>
        <el-form-item label="负荷计算系数2" prop="loadParam2">
            <el-input v-model="ruleForm.loadParam2" style="width:300px;margin-right:5px;" placeholder="请设置负荷计算系数2"/>
        </el-form-item>
        <el-form-item label="满载供热系数1" prop="fullParam1">
            <el-input v-model="ruleForm.fullParam1" style="width:300px;margin-right:5px;" placeholder="请设置满载供热系数1"/>
        </el-form-item>
        <el-form-item label="满载供热系数2" prop="fullParam2">
            <el-input v-model="ruleForm.fullParam2" style="width:300px;margin-right:5px;" placeholder="请设置满载供热系数2"/>
        </el-form-item>
        <el-form-item label="满载供热系数3" prop="fullParam3">
            <el-input v-model="ruleForm.fullParam3" style="width:300px;margin-right:5px;" placeholder="请设置满载供热系数3"/>
        </el-form-item>
        <el-form-item label="满载供热系数4" prop="fullParam4">
            <el-input v-model="ruleForm.fullParam4" style="width:300px;margin-right:5px;" placeholder="请设置满载供热系数4"/>
        </el-form-item>
        <el-form-item label="满载供热系数5" prop="fullParam5">
            <el-input v-model="ruleForm.fullParam5" style="width:300px;margin-right:5px;" placeholder="请设置满载供热系数5"/>
        </el-form-item>
        <el-form-item label="满载供热系数6" prop="fullParam6">
            <el-input v-model="ruleForm.fullParam6" style="width:300px;margin-right:5px;" placeholder="请设置满载供热系数6"/>
        </el-form-item>
        <el-form-item label="最大制热量系数" prop="maxParam1">
            <el-input v-model="ruleForm.maxParam1" style="width:300px;margin-right:5px;" placeholder="请设置最大制热量系数"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        id: null,
        project: this.project,
        temperature: null,
        loadPower: null,
        loadParam1: null,
        loadParam2: null,
        fullParam1: null,
        fullParam2: null,
        fullParam3: null,
        fullParam4: null,
        fullParam5: null,
        fullParam6: null,
        maxParam1: null,
      },
      rules: {
        temperature: [
          {
            required: true,
            message: "请设置制热目标温度",
            trigger: "blur",
          }
        ],
        loadPower: [
          {
            required: true,
            message: "请设置热负荷预测值",
            trigger: "blur",
          }
        ],
        loadParam1: [
          {
            required: true,
            message: "请设置负荷计算系数1",
            trigger: "blur",
          }
        ],
        loadParam2: [
          {
            required: true,
            message: "请设置负荷计算系数2",
            trigger: "blur",
          }
        ],
        fullParam1: [
          {
            required: true,
            message: "请设置满载供热量系数1",
            trigger: "blur",
          }
        ],
        fullParam2: [
          {
            required: true,
            message: "请设置满载供热量系数2",
            trigger: "blur",
          }
        ],
        fullParam3: [
          {
            required: true,
            message: "请设置满载供热量系数3",
            trigger: "blur",
          }
        ],
        fullParam4: [
          {
            required: true,
            message: "请设置满载供热量系数4",
            trigger: "blur",
          }
        ],
        fullParam5: [
          {
            required: true,
            message: "请设置满载供热量系数5",
            trigger: "blur",
          }
        ],
        fullParam6: [
          {
            required: true,
            message: "请设置满载供热量系数6",
            trigger: "blur",
          }
        ],
        maxParam1: [
          {
            required: true,
            message: "请设置最大制热量系数",
            trigger: "blur",
          }
        ],
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["settingVisible","project"],
  watch: {
    settingVisible(newValue) {
      if (newValue) {
        this.querySetting();
      }
    },
  },
  methods: {
    onInput() {
      this.$forceUpdate();
    },
    handleClose() {
      this.$emit("viewClose");
    },
    querySetting(){
      var params={
        project: this.project
      };
      this.request.querySetting(params).then((res) => {
        if (res.code==0) {
          this.ruleForm.id=res.data.id;
          this.ruleForm.project=res.data.project;
          this.ruleForm.temperature=res.data.temperature;
          this.ruleForm.loadPower=res.data.loadPower;
          this.ruleForm.loadParam1=res.data.loadParam1;
          this.ruleForm.loadParam2=res.data.loadParam2;
          this.ruleForm.fullParam1=res.data.fullParam1;
          this.ruleForm.fullParam2=res.data.fullParam2;
          this.ruleForm.fullParam3=res.data.fullParam3;
          this.ruleForm.fullParam4=res.data.fullParam4;
          this.ruleForm.fullParam5=res.data.fullParam5;
          this.ruleForm.fullParam6=res.data.fullParam6;
          this.ruleForm.maxParam1=res.data.maxParam1;
        } else {
          this.$message.error("设置参数获取失败");
        }
      });
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.request.saveSetting(this.ruleForm).then((res) => {
            if (res.code==0) {
              this.$message.success("设置成功");
              this.$emit("viewClose");
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.form-body {
  width:550px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  box-sizing:border-box;
}
</style>