<template>
  <div class="pro-info">
    <div class="left-data">
      <div class="device">
        <div style="margin-bottom:10px;font-size:14px;font-weight:bold;">设备列表</div>
        <div class="device-tree">
          <div class="tree">
            <el-tree ref="tree" :data="deviceList" node-key="id" :props="deviceProps" @node-click="treeClick" :expand-on-click-node="true">
              <span class="custom-tree-node show-hide" slot-scope="{ node, data }">
                <div class="online" v-if="data.online=='yes'">
                  <template v-if="data.nodeList"><span style="font-weight: bold;"> {{ data.nick }} </span> <span style="font-size:11px;font-weight: bold;">{{ subStr(data.gateway) }}</span></template>
                   <template v-else><span> {{ node.label }} </span></template>
                </div>
                <div v-else class="offline">
                  <span>{{ data.nick }} </span> <span v-if="data.nodeList" style="font-size:12px;">{{ subStr(data.gateway) }}</span>
                </div>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="currentDevice!=null&&currentGroup==null">
        <div class="device-info">
          <div v-if="currentDevice.port!=null" class="row-item">
            <div class="param-item">
              设备编号：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.deviceId }}</span>
            </div>
            <div class="param-item">
              通讯协议：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.ptype }}</span>
            </div>
            <div class="param-item">
              通讯端口：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.port }}</span>
            </div>
            <div class="param-item">
              网络IP地址：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.ip==null?'无':currentDevice.ip }}</span>
            </div>
          </div>
          <div class="row-item">
            <div class="param-item">
              设备名称：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.nick }}</span>
            </div>
            <div class="param-item">
              是否在线：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.online=='yes'?'是':'否' }}</span>
            </div>
            <div class="param-item">
              从机地址：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.address }}</span>
            </div>
            <div class="param-item">
              通讯波特率：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.baudRate }}</span>
            </div>
          </div>
          <div class="row-item">
            <div class="param-item">
              设备类型：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.categoryName }}</span>
            </div>
            <div class="param-item">
              设备型号：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.modelName }}</span>
            </div>
            <div class="param-item">
              制造厂商：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.manufacturerName }}</span>
            </div>
            <div class="param-item">
              {{ currentDevice.port!=null?'边缘服务器：':'网关编号：' }}<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.gateway }}</span>
            </div>
          </div>
          <div class="row-item">
            <div class="param-item">
              解码协议：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.codec }}</span>
            </div>
            <div class="param-item">
              奇偶校验：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.parityCheck }}</span>
            </div>
            <div class="param-item">
              数据位：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.dataBits }}</span>
            </div>
            <div class="param-item">
              停止位：<span style="color:#409eff;margin-top:5px;font-weight:bold;">{{ currentDevice.stopBits }}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="currentDevice==null" style="font-size:14px;margin-top:20px;margin-left:20px;">还未绑定设备</div>
      </template>
      <template v-if="currentDevice!=null&&currentGroup!=null">
        <template v-if="deviceData[currentGroup.name]!=null&&deviceData[currentGroup.name].length>0">
          <template v-for="(item, index) in deviceData[currentGroup.name]" >
            <div class="data-row" v-if="index%4==0" :key="index">
              <div style="width:22.5%;">
                <div class="param-item1">
                  <div class="param-name">
                    {{item.param.chinese}}
                  </div>
                  <div class="param-date">
                    {{item.timestamp!=null?item.timestamp:dataTime}}
                  </div>
                  <div class="param-data">
                    <div class="param-value">
                      {{getValue(item)+' '+(item.param.unit==null?'':item.param.unit)}}
                    </div>
                    <div class="param-icon">
                      <img style="width:20px;height:20px;" :src="iconFilter">
                    </div>
                  </div>
                </div>
              </div>
              <div style="width:22.5%;margin-left:2%;">
                <template v-if="deviceData[currentGroup.name][index+1]!=null">
                  <div class="param-item2">
                    <div class="param-name">
                      {{deviceData[currentGroup.name][index+1].param.chinese}}
                    </div>
                    <div class="param-date">
                      {{deviceData[currentGroup.name][index+1].timestamp!=null?deviceData[currentGroup.name][index+1].timestamp:dataTime}}
                    </div>
                    <div class="param-data">
                      <div class="param-value">
                        {{getValue(deviceData[currentGroup.name][index+1])+' '+(deviceData[currentGroup.name][index+1].param.unit==null?'':deviceData[currentGroup.name][index+1].param.unit)}}
                      </div>
                      <div class="param-icon">
                        <img style="width:20px;height:20px;" :src="iconFilter">
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div style="width:22.5%;margin-left:2%;">
                <template v-if="deviceData[currentGroup.name][index+2]!=null">
                  <div class="param-item3">
                    <div class="param-name">
                      {{deviceData[currentGroup.name][index+2].param.chinese}}
                    </div>
                    <div class="param-date">
                      {{deviceData[currentGroup.name][index+2].timestamp!=null?deviceData[currentGroup.name][index+2].timestamp:dataTime}}
                    </div>
                    <div class="param-data">
                      <div class="param-value">
                        {{getValue(deviceData[currentGroup.name][index+2])+' '+(deviceData[currentGroup.name][index+2].param.unit==null?'':deviceData[currentGroup.name][index+2].param.unit)}}
                      </div>
                      <div class="param-icon">
                        <img style="width:20px;height:20px;" :src="iconFilter">
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div style="width:22.5%;margin-left:2%;">
                <template v-if="deviceData[currentGroup.name][index+3]!=null">
                  <div class="param-item4">
                    <div class="param-name">
                      {{deviceData[currentGroup.name][index+3].param.chinese}}
                    </div>
                    <div class="param-date">
                      {{deviceData[currentGroup.name][index+3].timestamp!=null?deviceData[currentGroup.name][index+3].timestamp:dataTime}}
                    </div>
                    <div class="param-data">
                      <div class="param-value">
                        {{getValue(deviceData[currentGroup.name][index+3])+' '+(deviceData[currentGroup.name][index+3].param.unit==null?'':deviceData[currentGroup.name][index+3].param.unit)}}
                      </div>
                      <div class="param-icon">
                        <img style="width:20px;height:20px;" :src="iconFilter">
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
            <div style="font-size:14px;margin-top:20px;margin-left:20px;">未获取到设备数据</div>
        </template>
      </template>
    </div>  
  </div>
</template>

<script>
import { DateToStr } from "../../../utils/util/util";
import { mapState } from "vuex";
export default {
  data() {
    return {
      iconFilter: require("./images/icon_filter.png"),
      deviceProps: {
        children: "nodeList",
        label: "name"
      },
      first: true,
      retry: 0,
      socket: null,
      projectInfo: {},
      deviceList: [],
      paramList: [],
      currentGroup: null,
      currentDevice: null,
      currentData: [],
      deviceData: {},
      dataTime: "",  
    };
  },
  components: {},
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  mounted() {
    this.projectInfo=this.projectCurrentRowData;
    this.getDeviceGroup();
    this.initWebsocket();
  },
  methods: {
    getDeviceGroup() {
      var params={
        "project":this.projectInfo.projectCode,
      };
      this.request.deviceGroup(params).then((res) => {
        if (res.code == 0) {
          this.deviceList=res.data;
          this.currentDevice=this.deviceList[0];
          this.currentGroup=null;
          this.getParamList();
          this.subscribe(this.currentDevice.deviceId);
        }
      });
    },
    treeClick(e) {
      if (e.categoryName!=null) {
        this.currentDevice=e;
        this.currentGroup=null;
      } else {
        var deviceGroup=e;
        if (this.currentGroup==null||deviceGroup.deviceId!=this.currentGroup.deviceId) {
          for (var i=0; i<this.deviceList.length; i++) {
            if (deviceGroup.deviceId==this.deviceList[i].deviceId) {
              this.unsubscribe(this.currentDevice.deviceId);
              this.currentDevice=this.deviceList[i];
              this.currentGroup=deviceGroup;
              this.getParamList();
              this.subscribe(this.currentDevice.deviceId);
              break;
            }
          }
        } else {
          this.currentGroup=e;
        }
      }
    },
    getLastData() {
      var params={
        "deviceId":this.currentDevice.deviceId,
        "alarmFlag":"yes"
      }
      this.request.readDataLast(params).then((res) => {
        if (res.code == 0) {
          this.dataTime="@"+DateToStr(new Date(res.data.timestamp));
          this.deviceData={};
          var tempData=res.data.data;
          for (var i=0; i<tempData.length; i++) {
            for (var j=0; j<this.paramList.length; j++) {
              if (tempData[i].name==this.paramList[j].name) {
                tempData[i].param=this.paramList[j];
                if (this.deviceData[this.paramList[j].groupName]==null) {
                  this.deviceData[this.paramList[j].groupName]=[];
                }
                this.deviceData[this.paramList[j].groupName].push(tempData[i]);
              }
            }
          }
        }
      });
    },
    getParamList() {
      var params={
        "deviceId":this.currentDevice.deviceId,
        "alarmFlag":"yes"
      }
      this.request.deviceParams(params).then((res) => {
        if (res.code == 0) {
          this.paramList=res.data;
          for (var i=0; i<this.paramList.length; i++) {
            if (this.paramList[i].groupName==null||this.paramList[i].groupName=='') {
              this.paramList[i].groupName="未分类";
            }
          }
          this.getLastData();
        }
      });
    },
    parseData(json) {
      var timeStr="@"+DateToStr(new Date(json['timestamp']));
      for (var i=0; i<this.paramList.length; i++) {
        if (json[this.paramList[i].name]!=null) {
          var groupData=this.deviceData[this.paramList[i].groupName];
          if (groupData!=null&&groupData.length>0) {
            for (var j=0; j<groupData.length; j++) {
              var item=groupData[j];
              item.value=json[this.paramList[i].name];
              item.timestamp=timeStr;
            }
          }
        }
      }
    },
    subscribe(deviceId) {
      var params={
        "command":"subscribe",
        "deviceId": deviceId
      }
      this.sendMessage(JSON.stringify(params));
    },
    unsubscribe(deviceId) {
      var params={
        "command":"unsubscribe",
        "deviceId": deviceId
      }
      this.sendMessage(JSON.stringify(params));
    },
    sendMessage(msg) {
        if (typeof (WebSocket) == "undefined") {
            console.log("您的浏览器不支持WebSocket");
        } else {
          if (this.socket.readyState==WebSocket.OPEN) {
            this.socket.send(msg);
            console.log("socket.send "+msg);
          } else {
            this.retry++;
            console.log("socketState="+this.socket.readyState);
            if (this.retry<10) {
              setTimeout(() => {
                this.sendMessage(msg);
              },1000);
            }
          }
        }
    },
    initWebsocket() {
      if (typeof (WebSocket) == "undefined") {
          console.log("您的浏览器不支持WebSocket");
      } else {
          console.log("您的浏览器支持WebSocket");
          //实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
          var wssURL="wss://saas-cn.moreinno.com/websocket/" + localStorage.getItem('token');
          console.log("wsURL-->" + wssURL);
          this.socket = new WebSocket(wssURL);
          //打开事件
          this.socket.onopen = function () {
            console.log("Socket 已打开");
          };
          //获得消息事件
          const that=this;
          this.socket.onmessage = function (msg) {
            console.log(msg);
            let json=JSON.parse(msg.data);
            if (json!=null&&Object.keys(json).length>10) {
              that.parseData(json);
            }
          };
          //关闭事件
          this.socket.onclose = function () {
            console.log("Socket已关闭");
          };
          //发生了错误事件
          this.socket.onerror = function () {
            alert("Socket发生了错误");
          }
      }
    },
    getValue(item) {
      let num=item.value;
      if (item.param!=null&&item.param!=undefined) {
        let fanwei=item.param.fanwei;
        if (fanwei==""||fanwei==null||fanwei==undefined) {
          return this.formatNum(num);
        } else {
          if (fanwei.includes(":")) {
            let array=fanwei.split(";");
            for (var i=0; i<array.length; i++) {
              let data=array[i].split(":");
              if ((""+num)==data[0]) {
                return data[1];
              }
            } 
          }
        }
      }
      return this.formatNum(num);
    },
    formatNum(num) {
      if (num==null||num==undefined) {
        return "";
      } else {
        let array=(""+num).split(".");
        if (array.length!=2) {
          return num;
        } else if (array[1].length>4) {
          return array[0]+"."+array[1].substring(0,4);
        } else {
          return num;
        }
      }
    },
    subStr(gateway) {
      if (gateway==null||gateway=='') {
        return "";
      } else {
        if (gateway.length<=4) {
          return gateway;
        } else {
          return " ("+gateway.substr(gateway.length-4,4)+")";
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
.device-info {
  width: 92%;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 2%;
  margin-right: 2%;
  padding-left: 4%;
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.row-item{
  display: flex;
  flex-direction: row;
  width:100%;
  margin-top: 10px;
}
.param-item {
  width: 25%;
  font-size:14px;
  height:20px;
  overflow-x: auto;
  overflow-y: hidden;
}
::-webkit-scrollbar {
    display: none;
}
.content {
  width: 83%;
  height: 98%;
  font-family: "microsoft yahei ui","microsoft yahei";
  padding-bottom: 2%;
  background-color: whitesmoke;
  overflow: auto;
}
.online {
  color: black
}
.offline {
  color: black
}
.left-data {
  width: 17%;
  height: 99%;
  font-family: "microsoft yahei ui","microsoft yahei";
  margin-bottom: 1%;
}
.device {
  height: 100%;
  padding-left: 10px;
  background: #ffffff;
  overflow: auto;
}
.pro-info {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
.el-tree ::v-deep.el-tree-node>.el-tree-node__content {
    height: 40px;
}
.show-hide:hover :nth-child(2) {
    display: inline-block !important;
}
.device-tree {
  width: 96%;
  height: 96%;
  padding: 1%;
  margin: 1%;
  box-sizing: border-box;
  flex-shrink: 0;
}
.content-data {
  width: 100%;
  height: 150px;
  background-color: whitesmoke;
}
.data-row {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.param-item1 {
  width: 94%;
  height: 110px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.param-item2 {
  width: 94%;
  height: 110px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.param-item3 {
  width: 94%;
  height: 110px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.param-item4 {
  width: 94%;
  height: 110px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.param-name {
  color: black;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.param-date {
  color: gray;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
}
.param-data {
  display: flex;
  flex-direction: row;
  height: 65px;
  line-height: 65px;
  justify-content: center;
}
.param-value {
  width: 90%;
  color: #409eff;
  font-size: 22px;
}
.param-icon {
  width: 10%;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
