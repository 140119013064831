const civilBuild = {
  name: "民用住宅",
  id: 10,
  typeList: [
    {
      name: "高层住宅",
      id: 10,
    },
    {
      name: "别墅",
      id: 20,
    },
    {
      name: "自建房",
      id: 30,
    },
    {
      name: "多层住宅",
      id: 40,
    },
    {
      name: "其他",
      id: 50,
    }
  ]
}

const commercialBuild = {
  name: "商用住宅",
  id: 20,
  typeList: [
    {
      name: "商业中心",
      id: 10,
    },
    {
      name: "科技园区",
      id: 20,
    },
    {
      name: "星级酒店",
      id: 30,
    },
    {
      name: "智慧农业",
      id: 40,
    },
    {
      name: "集中供暖",
      id: 50,
    },
    {
      name: "数据中心",
      id: 60,
    },
    {
      name: "工厂",
      id: 70,
    },
    {
      name: "其他",
      id: 80,
    }
  ]
}

const publicBuild = {
  name: "公用建筑",
  id: 30,
  typeList: [
    {
      name: "学校",
      id: 10,
    },
    {
      name: "医院",
      id: 20,
    },
    {
      name: "政府办公大楼",
      id: 30,
    },
    {
      name: "交通枢纽",
      id: 40,
    },
    {
      name: "其他",
      id: 50,
    }
  ]
}

const buildStatic = [
  {
    name: "高层住宅",
    id: "10,10",
  },
  {
    name: "别墅",
    id: "10,20",
  },
  {
    name: "自建房",
    id: "10,30",
  },
  {
    name: "多层住宅",
    id: "10,40",
  },
  {
    name: "其他",
    id: "10,50",
  },
  {
    name: "商业中心",
    id: "20,10",
  },
  {
    name: "科技园区",
    id: "20,20",
  },
  {
    name: "星级酒店",
    id: "20,30",
  },
  {
    name: "智慧农业",
    id: "20,40",
  },
  {
    name: "集中供暖",
    id: "20,50",
  },
  {
    name: "数据中心",
    id: "20,60",
  },
  {
    name: "工厂",
    id: "20,70",
  },
  {
    name: "其他",
    id: "20,80",
  },
  {
    name: "学校",
    id: "30,10",
  },
  {
    name: "医院",
    id: "30,20",
  },
  {
    name: "政府办公大楼",
    id: "30,30",
  },
  {
    name: "交通枢纽",
    id: "30,40",
  },
  {
    name: "其他",
    id: "30,50",
  }
]


const build =  {
  civilBuild,
  commercialBuild,
  publicBuild,
  buildEnum: {
    10: "civilBuild",
    20: "commercialBuild",
    30: "publicBuild"
  },
  buildStatic
}

export default build;