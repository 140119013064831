import { $ajax } from '../api'
import { $dxios } from '../api'

// 项目管理
export default class Project {

    constructor() { }

    static projectMgt = {
        projectList: '/api/mo-project/project/projectList.action',          // 查询项目
        projectAdd: '/api/mo-project/project/projectAdd.action',            // 新增项目
        projectEdit: '/api/mo-project/project/projectEdit.action',          // 编辑项目
        electricityEdit: '/api/mo-project/project/electricityEdit.action',  // 编辑电价
        electricityDel: '/api/mo-project/project/electricityDel.action',    // 删除电价
        electricityList: '/api/mo-project/project/electricityList.action',  // 获取电价列表
        projectSummary: '/api/mo-project/project/summary.action',           // 租户项目汇总信息
        projectSearchAll: '/api/mo-project/project/searchAll.action',       // 快速搜索
        projectInfo: '/api/mo-project/project/projectInfo.action',          // 项目详情
        projectDelete: '/api/mo-project/project/delete.action',             // 删除项目
        listUser: '/api/mo-project/project/listUser.action',                // 项目用户
        removeUser: '/api/mo-project/project/removeUser.action',            // 移除用户
        listAccount: '/api/mo-project/project/listAccount.action',          // 内部用户
        placeList: '/api/mo-project/project/placeList.action',              //查询项目
        placeAdd: '/api/mo-project/project/placeAdd.action',                //新增建筑
        placeEdit: '/api/mo-project/project/placeEdit.action',              //编辑建筑
        placeDel: '/api/mo-project/project/placeDel.action',                //删除建筑
        gatewayList: '/api/mo-project/project/gatewayList.action',          //查询网关
        gatewayAdd: '/api/mo-project/project/gatewayAdd.action',            //新增网关
        gatewayEdit: '/api/mo-project/project/gatewayEdit.action',          //编辑网关
        gatewayDel: '/api/mo-project/project/gatewayDel.action',            //删除网关
        deviceList: '/api/mo-project/project/deviceList.action',            //查询设备
        deviceAdd: '/api/mo-project/project/deviceAdd.action',              //新增设备
        deviceEdit: '/api/mo-project/project/deviceEdit.action',            //编辑设备
        deviceDel: '/api/mo-project/project/deviceDel.action',              //删除设备 
        projectChartSet: '/api/mo-project/project/projectChartSet.action',  //首页参数设置
        projectChartRead: '/api/mo-project/project/projectChartRead.action',//首页参数读取
        heartBeat: '/api/mo-project/project/heartBeat.action',              //设置心跳间隔
        baudRate: '/api/mo-project/project/baudRate.action',                //设置波特率
        deviceGroup: '/api/mo-project/project/deviceGroup.action',          //参数分组名称列表
        listProject: '/api/mo-project/project/listProject.action',          //项目列表信息
        

    }
    
    //项目列表信息
    static listProject =  (params) => {
        return $ajax.post(`${this.projectMgt.listProject}`, params).then(res => res.data)
    }

    //内部用户列表
    static listAccount =  (params) => {
        return $ajax.post(`${this.projectMgt.listAccount}`, params).then(res => res.data)
    }


    //参数分组名称列表
    static deviceGroup =  (params) => {
        return $ajax.post(`${this.projectMgt.deviceGroup}`, params).then(res => res.data)
    }

    //设置波特率
    static baudRate =  (params) => {
        return $ajax.post(`${this.projectMgt.baudRate}`, params).then(res => res.data)
    }

    //设置心跳间隔
    static heartBeat =  (params) => {
        return $ajax.post(`${this.projectMgt.heartBeat}`, params).then(res => res.data)
    }

    //移除用户
    static removeUser =  (params) => {
        return $ajax.post(`${this.projectMgt.removeUser}`, params).then(res => res.data)
    }

    //项目用户
    static listUser =  (params) => {
        return $ajax.post(`${this.projectMgt.listUser}`, params).then(res => res.data)
    }

    //删除项目
    static projectDelete =  (params) => {
        return $ajax.post(`${this.projectMgt.projectDelete}`, params).then(res => res.data)
    }

    //查询项目
    static projectList = (params) => {
        return $ajax.post(`${this.projectMgt.projectList}`, params).then(res => res.data)
    }

    //查询项目
    static projectAdd = (params) => {
        return $ajax.post(`${this.projectMgt.projectAdd}`, params).then(res => res.data)
    }

    //查询项目
    static projectEdit = (params) => {
        return $ajax.post(`${this.projectMgt.projectEdit}`, params).then(res => res.data)
    }
    
    // 查询电价列表
    static electricityList = (params) => {
      return $ajax.post(`${this.projectMgt.electricityList}`, params).then(res => res.data)
    }

    // 删除电价
    static electricityDel = (params) => {
      return $ajax.post(`${this.projectMgt.electricityDel}`, params).then(res => res.data)
    }

    // 编辑电价
    static electricityEdit = (params) => {
      return $ajax.post(`${this.projectMgt.electricityEdit}`, params).then(res => res.data)
    }

    //查询设备
    static deviceList = (params) => {
        return $ajax.post(`${this.projectMgt.deviceList}`, params).then(res => res.data)
    }

    //新增设备
    static deviceAdd = (params) => {
        return $ajax.post(`${this.projectMgt.deviceAdd}`, params).then(res => res.data)
    }

    //编辑设备
    static deviceEdit = (params) => {
        return $ajax.post(`${this.projectMgt.deviceEdit}`, params).then(res => res.data)
    }
    //删除设备
    static deviceDel = (params) => {
        return $ajax.post(`${this.projectMgt.deviceDel}`, params).then(res => res.data)
    }



    //查询建筑
    static placeList = (params) => {
        return $ajax.post(`${this.projectMgt.placeList}`, params).then(res => res.data)
    }

    //新增建筑
    static placeAdd = (params) => {
        return $ajax.post(`${this.projectMgt.placeAdd}`, params).then(res => res.data)
    }

    //编辑建筑
    static placeEdit = (params) => {
        return $ajax.post(`${this.projectMgt.placeEdit}`, params).then(res => res.data)
    }
    //删除建筑
    static placeDel = (params) => {
        return $ajax.post(`${this.projectMgt.placeDel}`, params).then(res => res.data)
    }



    //查询网关
    static gatewayList = (params) => {
        return $ajax.post(`${this.projectMgt.gatewayList}`, params).then(res => res.data)
    }

    //新增网关
    static gatewayAdd = (params) => {
        return $ajax.post(`${this.projectMgt.gatewayAdd}`, params).then(res => res.data)
    }

    //编辑网关
    static gatewayEdit = (params) => {
        return $ajax.post(`${this.projectMgt.gatewayEdit}`, params).then(res => res.data)
    }
    //删除网关
    static gatewayDel = (params) => {
        return $ajax.post(`${this.projectMgt.gatewayDel}`, params).then(res => res.data)
    }



    //查询
    static gatewayList = (params) => {
        return $ajax.post(`${this.projectMgt.gatewayList}`, params).then(res => res.data)
    }

    //新增
    static gatewayAdd = (params) => {
        return $ajax.post(`${this.projectMgt.gatewayAdd}`, params).then(res => res.data)
    }

    //编辑
    static gatewayEdit = (params) => {
        return $ajax.post(`${this.projectMgt.gatewayEdit}`, params).then(res => res.data)
    }
    //删除
    static gatewayDel = (params) => {
        return $ajax.post(`${this.projectMgt.gatewayDel}`, params).then(res => res.data)
    }

    // 租户项目汇总
    static projectSummary = (params) => {
      return $ajax.post(`${this.projectMgt.projectSummary}`, params).then(res => res.data)
    }

    // 快速搜索
    static projectSearchAll = (params) => {
      return $ajax.post(`${this.projectMgt.projectSearchAll}`, params).then(res => res.data)
    }

    // 项目详情
    static projectInfo = (params) => {
      return $ajax.post(`${this.projectMgt.projectInfo}`, params).then(res => res.data)
    }

    // 首页参数设置
    static projectChartSet = (params) => {
        return $ajax.post(`${this.projectMgt.projectChartSet}`, params).then(res => res.data)
    }

    // 首页参数读取
    static projectChartRead = (params) => {
        return $ajax.post(`${this.projectMgt.projectChartRead}`, params).then(res => res.data)
    }

}